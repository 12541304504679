export const routes = {
    auth: {
      login: "/login",
      forgotPassword: "/forgot-password",
      resetPassword: "/reset-password"
    },
    menu: {
      home: "/",
      event: "/events",
      resource: "/resources",
      program: "/programs",
      contribution: "/contributions",
      message: "/messages",
      profile: "/profile",
      support: "/support",
      prayerRequest: "/prayer-request",
      testimony: "/testimony"
    },
    nonMenu:{
      dignitaries: "/dignitaries",
      membership: "/membership",
      eventDetails: "/eventDetails",
      programDetails: "/programDetails",
    }
  };
