import React from 'react'
import SearchAndButton from '../../layouts/searchAndButton'
import styles from './main.module.scss'
import MemberdhipForm from '../../layouts/membershipForm'

const MembershipPage = () => {
  return (
    <div>
        <SearchAndButton title={'Welcome to His Grace Anglican Church'}/>
        <div className={styles.container}>
            <h3>Membership Form</h3>
            <MemberdhipForm/>
        </div>
    </div>
  )
}

export default MembershipPage