import ContributionPage from "../components/contributions/main";
import Footer from "../layouts/footer";

const Contributions = () => {
  return (
    <div>
      <ContributionPage />
      <Footer/>
    </div>
  );
};

export default Contributions;
