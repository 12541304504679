import ResourcePage from "../components/resource/main";
import Footer from "../layouts/footer";

const Resource = () => {
  return (
    <>
      <ResourcePage />;
      <Footer />
    </>
  );
};

export default Resource;
