import EventLayout from "../../layouts/eventLayout";
import firstImage from "../../assets/FirstImage.svg";
import secondImage from "../../assets/SecondImage.svg";
import thirdImage from "../../assets/ThirdImage.svg";
import choir from "../../assets/choir.jpg";
import play from "../../assets/play.svg";
import share from "../../assets/share.svg";
import location from "../../assets/Location.svg";
import search from "../../assets/search.svg";
import styles from "./main.module.scss";
import bell from "../../assets/bell.svg";
import { redirectToYoutube } from "../../utils/types";
import streamImage from '../../assets/stream-hgac-img.jpeg'

const Main = () => {
  return (
    <div className={styles.homeSection}>
      <div className={styles.titleSection}>
        <h4 className={styles.title}>Welcome to His Grace Anglican Church</h4>
        <img src={bell} alt="bell" />
      </div>
      <div className={styles.miniHeader}>
        <div className={styles.searchContainer}>
          <img src={search} alt="search" className={styles.search} />
          <input type="text" placeholder="Search..." />
        </div>
        <button className={styles.liveBtn} onClick={redirectToYoutube}>Join Live Stream</button>
      </div>
      <div className={styles.imageBorder}>
        <div className={styles.imageContainer}>
          <img src={streamImage} alt="stream" className={styles.liveImage} />
          {/* <div className={styles.centered}>
            <img src={play} alt="play" />
          </div> */}
        </div>
        <div className={styles.eventAddressTags}>
          <div className={styles.eventContainer}>
            <p>Event Title</p>
            <div className={styles.buttonIcon}>
              <button className={styles.liveBtn} onClick={redirectToYoutube}>Join Live</button>
              <img src={share} alt="share" />
            </div>
          </div>
          <p className={styles.eventText}>
            A short summary about the details of the event
          </p>
          <div className={styles.timeLocation}>
            <div className={styles.location}>
              <img src={location} alt="location" />
              <p className={styles.eventText}>City Stadium</p>
            </div>
            <p className={styles.eventText}>8:00pm</p>
          </div>
          <div className={styles.tagSection}>
            <div className={styles.gbg}>
              <p>Crusade</p>
            </div>
            <div className={styles.ybg}>
              <p>Revival</p>
            </div>
            <div className={styles.bbg}>
              <p>Crossover</p>
            </div>
          </div>
        </div>
      </div>
      <p className={styles.boldText}>Upcoming Event</p>
      <div className={styles.eventLayoutRow}>
        <EventLayout
          image={firstImage}
          eventTitle={"Event Title"}
          summary={"A short summary about the details of the event"}
        />
        <EventLayout
          image={secondImage}
          eventTitle={"Event Title"}
          summary={"A short summary about the details of the event"}
        />
        <EventLayout
          image={thirdImage}
          eventTitle={"Event Title"}
          summary={"A short summary about the details of the event"}
        />
      </div>
      <p className={styles.boldText}>Past Events</p>
      <div className={styles.eventLayoutRow}>
        <EventLayout
          image={firstImage}
          eventTitle={"Event Title"}
          summary={"A short summary about the details of the event"}
        />
        <EventLayout
          image={secondImage}
          eventTitle={"Event Title"}
          summary={"A short summary about the details of the event"}
        />
        <EventLayout
          image={thirdImage}
          eventTitle={"Event Title"}
          summary={"A short summary about the details of the event"}
        />
      </div>
    </div>
  );
};

export default Main;
