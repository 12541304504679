import MembershipPage from '../components/membership/main'
import Footer from '../layouts/footer'

const Membership = () => {
  return (
    <>
        <MembershipPage />
        <Footer/>
    </>
  )
}

export default Membership