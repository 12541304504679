import styles from "./main.module.scss";
import bell from "../../assets/bell.svg";
import search from "../../assets/search.svg";
import { programs } from "../../utils/program";
import arrowRight from "../../assets/arrowRight.svg";
import { tagStyles } from "../../utils/tagStyles";
import { Link } from "react-router-dom";
import { redirectToYoutube } from "../../utils/types";

const Main = () => {
  return (
    <div>
      <div className={styles.titleSection}>
        <h4 className={styles.title}>Welcome to His Grace Anglican Church</h4>
        <img src={bell} alt="bell" />
      </div>
      <div className={styles.miniHeader}>
        <div className={styles.searchContainer}>
          <img src={search} alt="search" className={styles.search} />
          <input type="text" placeholder="Search..." />
        </div>
        <button className={styles.liveBtn} onClick={redirectToYoutube}>Join Live Stream</button>
      </div>
      <p className={styles.programText}>Programs</p>
      <div className={styles.programContainer}>
        <div className={styles.gridView}>
          {programs.map((program, index) => (
            <div key={index}>
              <img src={program.image} alt="" className={styles.programImage} />
              <p className={styles.programTitle}>{program.title}</p>
              <p className={styles.programSummary}>{program.summary}</p>
              <Link to={"/programDetails"}>
                <div className={styles.viewSection}>
                  <p>View Program</p>
                  <img src={arrowRight} alt="" />
                </div>
              </Link>
              <div className={styles.tagSection}>
                {program.tags.map((tag, idx) => {
                  const { backgroundColor, color } = tagStyles[tag];
                  return (
                    <span
                      key={idx}
                      style={{
                        backgroundColor,
                        color,
                        padding: "5px 10px",
                        borderRadius: "5px",
                        marginRight: "10px",
                        fontWeight: "400",
                        fontSize: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      {tag}
                    </span>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Main;
