import React from "react";
import styles from "./index.module.scss";
import search from "../../assets/search.svg";
import bell from '../../assets/bell.svg'
import { redirectToYoutube } from "../../utils/types";

type Props = {
  title: string
}

const SearchAndButton = ({title}: Props) => {
  return (
    <div>
      <div className={styles.titleSection}>
        <h4 className={styles.title}>{title}</h4>
        <img src={bell} alt="bell" />
      </div>
      <div className={styles.miniHeader}>
        <div className={styles.searchContainer}>
          <img src={search} alt="search" className={styles.search} />
          <input type="text" placeholder="Search..." />
        </div>
        <button className={styles.liveBtn} onClick={redirectToYoutube}>Join Live Stream</button>
      </div>
    </div>
  );
};

export default SearchAndButton;
