import { ConfigProvider } from "antd";
import { FC, PropsWithChildren } from "react";

export const Theme: FC<PropsWithChildren> = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Inter",
          colorPrimary: "#173C19",
          colorPrimaryHover: "#E7F5E8",
        },
        components: {
          Table: {
            headerBg: "#E7F5E8",
            headerBorderRadius: 0,
          },
          Modal: {
            padding: 0,
            paddingContentHorizontal: 0,
            paddingContentVertical: 0,
            //headerBg: "#E7F5E8",
            titleFontSize: 20,
            headerBg: '#FFFFFF',
          },
          Menu: {
            itemHoverBg: "#0B91F1",
            itemActiveBg: "#FFFFFF",
            itemBorderRadius: 16,
            itemHoverColor: "#011602",
            lineWidth: 0,
            colorBgContainer: "#0B91F1",
            itemHeight: 50,
            itemSelectedBg: "#FFFFFF",
            colorText: "#FFFFFF",
          },
          Drawer: {
            colorBgContainer: "#0B91F1"
          },
          Tabs: {
            colorText: "#555555",
            colorPrimaryHover: "#0B91F1",
            titleFontSize: 15,
            itemActiveColor: "#0B91F1",
            inkBarColor: "#0B91F1"
          
          }

        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};
