import React from "react";
import styles from "./index.module.scss";
import share from "../../assets/share.svg";
import location from "../../assets/Location.svg";
import arrowRight from "../../assets/arrowRight.svg";
import { Link } from "react-router-dom";

type EventLayoutProps = {
  image: string;
  eventTitle: string;
  summary: string;
};

const EventLayout: React.FC<EventLayoutProps> = ({
  image,
  eventTitle,
  summary,
}) => {
  return (
    <div>
      <div>
        <img src={image} alt="" />
        <div>
          <div className={styles.eventSect}>
            <p>{eventTitle}</p>
            <img src={share} alt="" />
          </div>
          <p className={styles.eventText}>{summary}</p>
          <div className={styles.timeLocation}>
            <div className={styles.location}>
              <img src={location} alt="location" />
              <p className={styles.eventText}>City Stadium</p>
            </div>
            <p className={styles.eventText}>8:00pm</p>
          </div>
          <Link to={"/eventDetails"}>
            <div className={styles.viewSection}>
              <p>View Details</p>
              <img src={arrowRight} alt="" />
            </div>
          </Link>
          <div className={styles.tagSection}>
            <div className={styles.tag1}>
              <p>Crusade</p>
            </div>
            <div className={styles.tag2}>
              <p>Revival</p>
            </div>
            <div className={styles.tag3}>
              <p>Crossover</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventLayout;
