import React from "react";
import EventDetailsPage from "../components/eventDetails/main";
import Footer from "../layouts/footer";

const EventDetails = () => {
  return (
    <>
      <EventDetailsPage />
      <Footer/>
    </>
  );
};

export default EventDetails;
