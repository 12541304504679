import React from "react";
import styles from "./main.module.scss";
import search from "../../assets/search.svg";
import Button from "../../custom/customButton/button";
import { Collapse, Tabs, type CollapseProps, type TabsProps } from 'antd';
import BankTransfer from "./bankTransfer";
import CreditDebitCard from "./creditDebitCard";
import Ussd from "./ussd";
import Flutterwave from "./flutterwave";
import SearchAndButton from "../../layouts/searchAndButton";




const accordion: CollapseProps['items'] = [
  {
    key: '1',
    label: (
      <div className={styles.collapseLabel}>
        <div className={styles.iconStyle}>
          <img src="/bank.svg" alt="" className={styles.icon}/>
        </div>
        <span>Bank Transfer</span>
      </div>
    ),
    children: <BankTransfer bankName={"First Bank"} accountName={"His Grace Anglican Church"} accountNumber={"2046890010"}/>,
  },
  {
    key: '2',
    label:(
      <div className={styles.collapseLabel}>
        <div className={styles.iconStyle}>
          <img src="/bank.svg" alt="" className={styles.icon}/>
        </div>
        <span>USSD Bank Account</span>
      </div>
    ),
    children: <Ussd/>,
  },
  {
    key: '3',
    label:(
      <div className={styles.collapseLabel}>
        <div className={styles.iconStyle}>
          <img src="/card.svg" alt="" className={styles.icon}/>
        </div>
        <span>Credit & Debit Card</span>
      </div>
    ),
    children: <CreditDebitCard/>,
  },
  {
    key: '4',
    label:(
      <div className={styles.collapseLabel}>
        <img src="/flutterwave.svg" alt="" className={styles.flutterwave}/>
        <span>Flutterwave</span>
      </div>
    ),
    children: <Flutterwave/>
  }
];

const items: TabsProps['items'] = [
  {
    key: '1',
    label: 'Offering',
    children: <Collapse items={accordion} expandIconPosition="right"/>, // Correctly render the Collapse component here
  },
  {
    key: '2',
    label: 'Tithe',
    children: <Collapse items={accordion} expandIconPosition="right"/>,
  },
  {
    key: '3',
    label: 'Seed of faith',
    children: <Collapse items={accordion} expandIconPosition="right"/>
  },
  {
    key: '4',
    label: 'Donation',
    children: <Collapse items={accordion} expandIconPosition="right"/>,
  },
];

const Main = () => {
  return (
    <div>
      <SearchAndButton title={"Contributions"}/>
      <h5 className={styles.contType}>Select Contribution Type</h5>
      <br />
      <Tabs items={items} /> {/* Render the Tabs component */}
    </div>
  );
};

export default Main;
