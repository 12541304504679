import Footer from "../layouts/footer";
import SearchAndButton from "../layouts/searchAndButton";
import ProfilePage from "../components/profile/main";

const Profile = () => {
  return (
    <>
      <ProfilePage />
      <Footer />
    </>
  );
};

export default Profile;
