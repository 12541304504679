import { Tabs, TabsProps } from "antd";
import SearchAndButton from "../../layouts/searchAndButton";
import { useEffect, useState } from "react";
import styles from "./main.module.scss";
import { programs } from "../../utils/program";
import lol from '../../assets/bulletin1.svg'

const onChange = (key: string) => {
  console.log(key);
};

const bulletins = [
  '/bulletin1.svg',
  '/bulletin2.svg',
  '/bulletin3.svg'
]

const items: TabsProps["items"] = [
  {
    key: "1",
    label: <span>Gallery</span>,
    children: (
      <div className={styles.galleryContainer}>
        <div className={styles.gridView}>
          {programs.map((item, index) => (
            <div key={index}>
              <img src={item.image} alt="" />
              <p>{item.title}</p>
              <span>{item.summary}</span>
            </div>
          ))}
        </div>
      </div>
    ),
  },
  {
    key: "2",
    label: <span>Bulletin</span>,
    children: (
      <div>
        <p>Book of Common Prayers</p>
        <div className={styles.galleryContainer}>
          <div className={styles.gridView}>
            {bulletins.map((item, index)=> (
              <div key={index}>
                <img src={item} alt="" className={styles.bulletin}/>
              </div>
            ))}
          </div>
        </div>
        <p>Hymn Book</p>
        <div className={styles.galleryContainer}>
          <div className={styles.gridView}>
            {bulletins.map((item, index)=> (
              <div key={index}>
                <img src={item} alt="" className={styles.bulletin}/>
              </div>
            ))}
          </div>
        </div>
      </div>
    ),
  },
  {
    key: "3",
    label: <span>Archive</span>,
    children: (
      <div>
      <p>Book of Common Prayers</p>
      <div className={styles.galleryContainer}>
        <div className={styles.gridView}>
          {bulletins.map((item, index)=> (
            <div key={index}>
              <img src={item} alt="" className={styles.bulletin}/>
            </div>
          ))}
        </div>
      </div>
      <p>Hymn Book</p>
      <div className={styles.galleryContainer}>
        <div className={styles.gridView}>
          {bulletins.map((item, index)=> (
            <div key={index}>
              <img src={item} alt="" className={styles.bulletin}/>
            </div>
          ))}
        </div>
      </div>
    </div>
    ),
  },
];

const Main = () => {
  const [tabBarGutter, setTabBarGutter] = useState(200);

  const updateTabBarGutter = () => {
    if (window.innerWidth < 768) {
      setTabBarGutter(30);
    } else {
      setTabBarGutter(200);
    }
  };

  useEffect(() => {
    updateTabBarGutter(); // Initial check when component mounts
    window.addEventListener("resize", updateTabBarGutter);

    return () => {
      window.removeEventListener("resize", updateTabBarGutter);
    };
  }, []);

  return (
    <div>
      <SearchAndButton title={"Resources"} />
      <Tabs
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        style={{ marginTop: 50 }}
        tabBarGutter={tabBarGutter}
      />
    </div>
  );
};

export default Main;
