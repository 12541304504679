import { Route, Routes } from 'react-router-dom';
import Home from './screens/home';
import DashboardLayout from './layouts/dashboardLayout';
import { routes } from "./routes";
import Event from './screens/event';
import Resource from './screens/resource';
import Program from './screens/program';
import DignitariesPage from './screens/dignitaries';
import Contributions from './screens/contributions';
import Profile from './screens/profile';
import Membership from './screens/membership';
import Support from './screens/support';
import EventDetails from './screens/eventDetails';
import PrayerRequest from './screens/prayerRequest';
import Testimony from './screens/testimony';
import ProgramDetails from './screens/programDetails';

function App() {

  const menuRoute = [
    {
      path: routes.menu.home,
      element: <Home />,
    },
    {
      path: routes.menu.event,
      element: <Event/>
    },
    {
      path: routes.menu.resource,
      element: <Resource/>
    },
    {
      path: routes.menu.program,
      element: <Program/>
    },
    {
      path: routes.menu.contribution,
      element: <Contributions/>
    },
    {
      path: routes.nonMenu.dignitaries,
      element: <DignitariesPage/>
    },
    {
      path: routes.menu.profile,
      element: <Profile/>
    },
    {
      path: routes.nonMenu.membership,
      element: <Membership/>
    },
    {
      path: routes.menu.support,
      element: <Support/>
    },
    {
      path: routes.nonMenu.eventDetails,
      element: <EventDetails/>
    },
    {
      path: routes.menu.prayerRequest,
      element: <PrayerRequest/>
    },
    {
      path: routes.menu.testimony,
      element: <Testimony/>
    },
    {
      path: routes.nonMenu.programDetails,
      element: <ProgramDetails/>
    }
  ]
  return (
    <Routes>
      <Route element={<DashboardLayout />}>
        {(menuRoute).map((item) => (
        <Route key={item.path} path={item.path} element={item.element} />
      ))}
      </Route>
  </Routes>

  
  );
}

export default App;
