import EventPage from "../components/events/main";
import Footer from "../layouts/footer";

const Event = () => {
  return (
    <div>
      <EventPage />
      <Footer/>
    </div>
  );
};

export default Event;