import styles from './index.module.scss'

const CreditDebitCard = () => {
  return (
    <div className={styles.container}>
      <h2>Payment Information</h2>
      <div className={styles.largeFlex}>
        <span>Card Number</span>
        <div className={styles.images}>
          <img src="/masterCard.svg" alt="" style={{marginRight: 8}}/>
          <img src="/visa.svg" alt="" />
        </div>
      </div>
      <div className={styles.cardInputField}>
      <input type="text" placeholder="2465 0012 7689 4532" />
      </div>
      <div className={styles.largeFlex}>
        <div className={styles.formattedStyling}>
          <span>Exp.Date</span>
          <div className={styles.inputStyling}>
            <input type="text" placeholder="04/27" />
          </div>
        </div>
        <div className={styles.subStyling}>
            <span>CVV</span>
            <div className={styles.inputStyling}>
            <input type="text" placeholder="***" />
            </div>
        </div>
      </div>
      <button className={styles.payment}>Make Payment</button>
    </div>
  );
};

export default CreditDebitCard;
