import styles from './index.module.scss'
import { message } from 'antd';

interface CopyTextProps{
    bankName: string;
    accountName: string;
    accountNumber: string
}

const BankTransfer = ({bankName, accountName, accountNumber}: CopyTextProps) => {
    const handleCopy = async(textToCopy: string) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            message.success('Text copied to clipboard!'); // Display success message
          } catch (err) {
            message.error('Failed to copy text'); // Display error message
          }
    }
  return (
    <div className={styles.container}>
        <div className={styles.styling}>
            <p>Bank Name</p>
            <div className={styles.smallFlex}>
                <p>{bankName}</p>
                <img src="/copy.svg" alt="" onClick={()=> handleCopy(bankName)}/>
            </div>
        </div>
        <div className={styles.styling}>
            <p>Account Name</p>
            <div className={styles.smallFlex}>
                <p>{accountName}</p>
                <img src="/copy.svg" alt="" onClick={()=> handleCopy(accountName)}/>
            </div>
        </div>
        <div className={styles.styling}>
            <p>Account Number</p>
            <div className={styles.smallFlex}>
                <p>{accountNumber}</p>
                <img src="/copy.svg" alt="" onClick={()=> handleCopy(accountNumber)}/>
            </div>
        </div>
    </div>
  )
}

export default BankTransfer