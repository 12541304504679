export const guestSpeakers = [
    {
        img: '/guestSpeaker1.svg',
        name: 'Michael Johnson',
        position: 'Sales Manager',
        description: 'Michael is a results-driven sales manager with a proven track record of exceeding targets.',
        socialMedia: ['/linkdinAccount.svg', '/Xaccount.svg', '/dribbleAccount.svg']
    },
    {
        img: '/guestSpeaker2.svg',
        name: 'Sarah Davis',
        position: 'Finance Analyst',
        description: 'Sarah is a detail-oriented finance analyst who ensures accurate financial reporting.',
        socialMedia: ['/linkdinAccount.svg', '/Xaccount.svg', '/dribbleAccount.svg']
    },
    {
        img: '/guestSpeaker3.svg',
        name: 'Michael Johnson',
        position: 'Sales Manager',
        description: 'Michael is a results-driven sales manager with a proven track record of exceeding targets.',
        socialMedia: ['/linkdinAccount.svg', '/Xaccount.svg', '/dribbleAccount.svg']
    },
    {
        img: '/guestSpeaker4.svg',
        name: 'Sarah Davis',
        position: 'Finance Analyst',
        description: 'Sarah is a detail-oriented finance analyst who ensures accurate financial reporting.',
        socialMedia: ['/linkdinAccount.svg', '/Xaccount.svg', '/dribbleAccount.svg']
    },
    
]