import ProgramPage from "../components/program/main";
import Footer from "../layouts/footer";

const Program = () => {
  return (
    <>
      <ProgramPage />
      <Footer/>
    </>
  );
};

export default Program;
