import React from "react";
import styles from "./index.module.scss";

const MemberdhipForm = () => {
  return (
    <div className={styles.container}>
      <div className={styles.inputFlex}>
        <div className={styles.inputContainer}>
          <p>First Name</p>
          <div className={styles.inputStyling}>
            <input type="text" placeholder="Enter your name" />
          </div>
        </div>
        <div className={styles.rightInputContainer}>
          <p>Last Name</p>
          <div className={styles.inputStyling}>
            <input type="text" placeholder="Enter your name" />
          </div>
        </div>
      </div>
      <div className={styles.inputFlex}>
        <div className={styles.inputContainer}>
          <p>Phone Number</p>
          <div className={styles.inputStyling}>
            <input type="text" placeholder="Enter your phone number" />
          </div>
        </div>
        <div className={styles.rightInputContainer}>
          <p>Email</p>
          <div className={styles.inputStyling}>
            <input type="email" placeholder="Enter your email" />
          </div>
        </div>
      </div>
      <div className={styles.inputFlex}>
        <div className={styles.inputContainer}>
          <p>Gender</p>
          <div className={styles.inputStyling}>
            <input type="text" placeholder="Enter your Gender" />
          </div>
        </div>
        <div className={styles.rightInputContainer}>
          <p>Date of Birth</p>
          <div className={styles.inputStyling}>
            <input type="email" placeholder="02/05/1998" />
          </div>
        </div>
      </div>
      <div>
        <p>About</p>
        <textarea name="" id=""></textarea>
      </div>
      <button className={styles.btn}>Save Details</button>
    </div>
  );
};

export default MemberdhipForm;
