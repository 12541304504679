import React from "react";
import styles from "./main.module.scss";
import arrowDown from "./../../assets/arrow-down.svg";
import profile from "./../../assets/profile-pic.svg";
import bell from "./../../assets/bell.svg";
import search from "./../../assets/search.svg"
import vicar from "./../../assets/vicar.svg"
import bishop from "./../../assets/bishop.svg"
import { programs } from "../../utils/program";
import SearchAndButton from "../../layouts/searchAndButton";
import { redirectToYoutube } from "../../utils/types";

const Dignitaries = () => {
  return (
    <div>
      <div className={styles.headerSection}>
        <h4 className={styles.title}>Dignitaries</h4>
        {/* <div className={styles.profileSection}>
          
          <img src={profile} alt="profile" className={styles.profile} />
          <div className={styles.nameSection}>
            <p className={styles.name}>Martin Akande</p>
            <p className={styles.position}>Member</p>
          </div>
          <img src={arrowDown} alt="arrow" className={styles.arrow} />
        </div> */}
        <img src={bell} alt="bell" />
      </div>
      <div className={styles.miniHeader}>
        <div className={styles.searchContainer}>
          <img src={search} alt="search" className={styles.search} />
          <input type="text" placeholder="Search..." />
        </div>
        <button className={styles.liveBtn} onClick={redirectToYoutube}>Join Live Stream</button>
      </div>
      <div className={styles.vicarSection}>
        <div className={styles.vicarNameSection}>
            <p className={styles.lead}>Our Leaders</p>
            <h4 className={styles.vicarTitle}>Bishop</h4>
            <p className={styles.name}>Ifedola Senasu Gabriel Okupevi</p>
        </div>
        <img src={bishop} alt="" className={styles.bishopImg}/>
      </div>
      <div className={styles.vicarSection}>
        <div className={styles.vicarNameSection}>
            <p className={styles.lead}>Our Leaders</p>
            <h4 className={styles.vicarTitle}>Our Vicar</h4>
            <p className={styles.name}>Engineer Innocent Jiji</p>
        </div>
        <img src={vicar} alt="" className={styles.vicarImg}/>
      </div>
      {/* <div>
        <p>Church Team</p>
        <div>
          <strong>Michael Johnson</strong>
          <p>Position</p>
          <p>A short bio about the dignitary in question</p>
        </div>
      </div> */}
      {
        programs.map((program, index)=> (
          <div>
            
          </div>
        ))
      }
    </div>
  );
};

export default Dignitaries;
