import HomePage from "../components/home/main";
import Footer from "../layouts/footer";

const Home = () => {
  return (
    <>
      <HomePage />
      <Footer/>
    </>
  );
};

export default Home;