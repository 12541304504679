import React from "react";
import ProgramDetailsPage from "../components/programDetails/main";
import Footer from "../layouts/footer";

const ProgramDetails = () => {
  return (
    <>
      <ProgramDetailsPage />
      <Footer/>
    </>
  );
};

export default ProgramDetails;
