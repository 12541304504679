import styles from "./dashboardLayout.module.scss";
import { ReactComponent as Menu } from "../assets/menu.svg";

const Header = ({ handleOpenSidebar }: { handleOpenSidebar: () => void }) => {

  const nthNumber = (number: number) => {
    if (number > 3 && number < 21) return "th";
    switch (number % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

const getDate = () => {
    const date = new Date();
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' })
    const year = date.getFullYear();
    const fullDate = [`${day}${nthNumber(day)}`,` ${month},`, year].join(' ');
    return `${fullDate}`;
}




  return (
    <header className={styles.header}>
      <section className={styles.headerMenuGroup}>
        <button onClick={handleOpenSidebar} className={styles.menuBtn}>
          <Menu />
        </button>
      </section>
      <span className={styles.date}>{getDate()}</span>
    </header>
  );
};

export default Header;
