import React from "react";
import styles from "./index.module.scss";

const items = [
  {
    name: "Fidelity Bank",
    code: "*770#",
  },
  {
    name: "FCMB",
    code: "*329#",
  },
  {
    name: "Gt Bank",
    code: "*737#",
  },
  {
    name: "Ecobank",
    code: "*326#",
  },
  {
    name: "Access Bank",
    code: "*901#",
  },
  {
    name: "Zenith Bank",
    code: "*966#",
  },
  {
    name: "First Bank",
    code: "*894#",
  },
  {
    name: "Wema Bank",
    code: "*945#",
  },
  {
    name: "Unity Bank",
    code: "*389*215#",
  },
  {
    name: "UBA",
    code: "*919#",
  },
  {
    name: "Union Bank",
    code: "*826#",
  },
  {
    name: "Heritage Bank",
    code: "*322*030#",
  },
  {
    name: "Keystone Bank",
    code: " *322*082#",
  },
  {
    name: "Standard IBTC Bank",
    code: "*909#",
  },
];
const Ussd = () => {
  return (
    <div className={styles.container}>
      <div className={styles.styling}>
        <strong>Banks</strong>
        <strong>USSD Codes</strong>
      </div>
      {items.map((item, index) => (
        <div className={styles.styling} key={index}>
          <p>{item.name}</p>
          <p>{item.code}</p>
        </div>
      ))}
    </div>
  );
};

export default Ussd;
