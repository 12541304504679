import React from "react";
import styles from "./index.module.scss";
import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import X from "../../assets/X.svg";
import youtube from "../../assets/youtube.svg";
import tiktok from "../../assets/tiktok.svg";

const Footer = () => {
  return (
    <footer>
      <div className={styles.biggerContainer}>
        <div className={styles.container}>
          <div>
            <p className={styles.boldText}>HGAC</p>
            <div className={styles.link}>
              <img src={facebook} alt="" className={styles.linkSpace} />
              <img src={X} alt="" className={styles.linkSpace} />
              <img src={tiktok} alt="" className={styles.linkSpace} />
              <img src={youtube} alt="" className={styles.linkSpace} />
              <img src={instagram} alt="" className={styles.lastMedia}/>
            </div>
          </div>
          <div className={styles.newsletter}>
            <p className={styles.semiBoldText}>Subscribe to our Newsletter</p>
            <form action="">
              <input type="text" placeholder="Enter your Email" />
              <button>Subscribe</button>
            </form>
            <p className={styles.smallText}>
              By subscribing you agree to with our Privacy Policy
            </p>
          </div>
        </div>
        <div className={styles.line}>
          <hr />
        </div>
        <div className={styles.termsandConds}>
          <div className={styles.terms}>
            <p className={styles.semiBoldText}>Privacy Policy</p>
            <p className={styles.semiBoldText}>Terms of Service</p>
            <p className={styles.semiBoldText}>Cookies Settings</p>
          </div>
          <div className={styles.copyright}>
            <p className={styles.semiBoldText}>© 2024 HGAC. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
