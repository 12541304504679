import React from "react";
import SearchAndButton from "../../layouts/searchAndButton";
import edit from "../../assets/edit.svg";
import styles from "./main.module.scss";

const Main = () => {
  return (
    <div>
      <SearchAndButton title={"Welcome to His Grace Anglican Church"} />
      <h4>Profile</h4>
      <div>
        <div className={styles.imageContainer}>
          <img src={edit} alt="" />
        </div>
        <div className={styles.formContainer}>
          <div className={styles.inputFlex}>
            <div className={styles.inputContainer}>
              <p>First Name</p>
              <div className={styles.inputStyling}>
                <input type="text" placeholder="Enter your name" />
              </div>
            </div>
            <div className={styles.rightInputContainer}>
              <p>Last Name</p>
              <div className={styles.inputStyling}>
                <input type="text" placeholder="Enter your name" />
              </div>
            </div>
          </div>
          <div className={styles.inputFlex}>
            <div className={styles.inputContainer}>
              <p>Phone Number</p>
              <div className={styles.inputStyling}>
                <input type="text" placeholder="Enter your phone number" />
              </div>
            </div>
            <div className={styles.rightInputContainer}>
              <p>Email</p>
              <div className={styles.inputStyling}>
                <input type="email" placeholder="Enter your email" />
              </div>
            </div>
          </div>
          <div className={styles.inputFlex}>
            <div className={styles.inputContainer}>
              <p>Gender</p>
              <div className={styles.inputStyling}>
                <input type="text" placeholder="Enter your Gender" />
              </div>
            </div>
            <div className={styles.rightInputContainer}>
              <p>Date of Birth</p>
              <div className={styles.inputStyling}>
                <input type="email" placeholder="02/05/1998" />
              </div>
            </div>
          </div>
          <div className={styles.inputFlex}>
            <div className={styles.inputContainer}>
              <p>Position</p>
              <div className={styles.inputStyling}>
                <input type="text" placeholder="Member" />
              </div>
            </div>
            <div className={styles.rightInputContainer}>
              <p>Favorite Bible Verse</p>
              <div className={styles.inputStyling}>
                <input type="email" placeholder="Bible Verse" />
              </div>
            </div>
          </div>
          <div>
            <p>About</p>
            <textarea name="" id=""></textarea>
          </div>
          <button className={styles.btn}>Save Details</button>
        </div>
      </div>
    </div>
  );
};

export default Main;
