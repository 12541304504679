import React from "react";
import PrayerRequestPage from "../components/prayerRequest/main";
import Footer from "../layouts/footer";

const PrayerRequest = () => {
  return (
    <>
      <PrayerRequestPage />
      <Footer/>
    </>
  );
};

export default PrayerRequest;
