import React from 'react'
import SearchAndButton from '../../layouts/searchAndButton'
import styles from './main.module.scss'

const main = () => {
  return (
    <div>
        <SearchAndButton title={'Prayer Request'}/>
        <h4>Prayer Request</h4>
        <div className={styles.formContainer}>
          <div className={styles.inputFlex}>
            <div className={styles.inputContainer}>
              <p>First Name</p>
              <div className={styles.inputStyling}>
                <input type="text" placeholder="Enter your name" />
              </div>
            </div>
            <div className={styles.rightInputContainer}>
              <p>Last Name</p>
              <div className={styles.inputStyling}>
                <input type="text" placeholder="Enter your name" />
              </div>
            </div>
          </div>
          <div className={styles.inputFlex}>
            <div className={styles.inputContainer}>
              <p>Email</p>
              <div className={styles.inputStyling}>
                <input type="email" placeholder="Enter your email" />
              </div>
            </div>
            <div className={styles.rightInputContainer}>
              <p>Phone Number</p>
              <div className={styles.inputStyling}>
                <input type="text" placeholder="Enter your phone number" />
              </div>
            </div>
          </div>
          <div>
            <p>Prayer Request</p>
            <textarea name="" id=""></textarea>
          </div>
          <button className={styles.btn}>Send Request</button>
        </div>
    </div>
  )
}

export default main