// styles.ts
export const tagStyles: { [key: string]: { backgroundColor: string; color: string } } = {
    Worship: { backgroundColor: '#FEFBEB', color: '#F6D433' },
    Prayer: { backgroundColor: '#E7F4FE', color: '#0B91F1' },
    Fellowship: { backgroundColor: '#FBF6EF', color: '#D9A25D' },
    Revival: { backgroundColor: '#FDEBEB', color: '#F25F5F' },
    Bible: { backgroundColor: '#FEFBEB', color: '#F6D433' },
    Knowlegde: { backgroundColor: '#E7F4FE', color: '#0B91F1' },
    Growth: { backgroundColor: '#FBF6EF', color: '#D9A25D' },
    Wisdom: { backgroundColor: '#FDEBEB', color: '#F25F5F' },
    Service: { backgroundColor: '#FEFBEB', color: '#F6D433' },
    Outreach: { backgroundColor: '#E7F4FE', color: '#0B91F1' },
    Compassion: { backgroundColor: '#E6F8EE', color: '#34C975' },
    Impact: { backgroundColor: '#FDEBEB', color: '#F25F5F' },
    Youth: { backgroundColor: '#FEFBEB', color: '#F6D433' },
    Spiritual: { backgroundColor: '#FDEBEB', color: '#F25F5F' },
    Healing: { backgroundColor: '#E6F8EE', color: '#34C975' },
};
