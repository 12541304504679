import styles from "./main.module.scss";
import bell from "../../assets/bell.svg";
import search from "../../assets/search.svg";
import choir from "../../assets/choir.jpg";
import play from "../../assets/play.svg";
import share from "../../assets/share.svg";
import location from "../../assets/Location.svg";
import EventLayout from "../../layouts/eventLayout";
import firstImage from "../../assets/FirstImage.svg";
import secondImage from "../../assets/SecondImage.svg";
import thirdImage from "../../assets/ThirdImage.svg";
import arrowRight from "../../assets/arrowRight.svg";
import vicar from "../../assets/vicar.svg";
import aboutUsImage from "../../assets/aboutUsImage.svg";
import praise from "../../assets/praiseAndWorship.jpg";
import { Link } from "react-router-dom";
import { tagStyles } from "../../utils/tagStyles";
import { programs } from "../../utils/program";
import { redirectToYoutube } from "../../utils/types";
import streamImage from '../../assets/stream-hgac-img.jpeg'

const Main = () => {
  const displayedPrograms = programs.slice(0, 3);
  return (
    <main>
      <div className={styles.titleSection}>
        <h4 className={styles.title}>Welcome to His Grace Anglican Church</h4>
        <img src={bell} alt="bell" />
      </div>
      <div className={styles.miniHeader}>
        <div className={styles.searchContainer}>
          <img src={search} alt="search" className={styles.search} />
          <input type="text" placeholder="Search..." />
        </div>
        <button className={styles.liveBtn} onClick={redirectToYoutube}>Join Live Stream</button>
      </div>
      <div className={styles.imageBorder}>
        <div className={styles.imageContainer}>
          <img src={streamImage} alt="stream" className={styles.liveImage} />
          {/* <div className={styles.centered}>
            <img src={play} alt="play" />
          </div> */}
        </div>
        <div className={styles.eventAddressTags}>
          <div className={styles.eventContainer}>
            <p>🌴2024 Harvest Thanksgiving🌴</p>
            <div className={styles.buttonIcon}>
              <button className={styles.liveBtn} onClick={redirectToYoutube}>Join Live</button>
              <img src={share} alt="share" className={styles.shareIcon}/>
            </div>
          </div>
          <p className={styles.subTitle}>
          THE LORD, MY EBENEZER 
          </p>
          <div className={styles.timeLocation}>
            <div className={styles.location}>
              <img src={location} alt="location" className={styles.locationIcon}/>
              <p className={styles.eventText}>Event Hall, Limeridge Hotel, Plot 10, Chevron Drive, Lekki, Lagos</p>
            </div>
            <p className={styles.eventText}>8:30am</p>
          </div>
          <div className={styles.tagSection}>
            <div className={styles.gbg}>
              <p>Family</p>
            </div>
            <div className={styles.ybg}>
              <p>Revival</p>
            </div>
            <div className={styles.bbg}>
              <p>Thanksgiving</p>
            </div>
          </div>
        </div>
      </div>
      <p className={styles.boldText}>Upcoming Event</p>
      <div className={styles.eventLayoutRow}>
        <EventLayout
          image={firstImage}
          eventTitle={"Event Title"}
          summary={"A short summary about the details of the event"}
        />
        <EventLayout
          image={secondImage}
          eventTitle={"Event Title"}
          summary={"A short summary about the details of the event"}
        />
        <EventLayout
          image={thirdImage}
          eventTitle={"Event Title"}
          summary={"A short summary about the details of the event"}
        />
      </div>
      <div className={styles.announcement}>
        <img src={thirdImage} alt="" />
        <div className={styles.announcementText}>
          <h4>Announcement</h4>
          <p>Join us today and Become a part of our Community</p>
        </div>
      </div>
      <div className={styles.vicarSection}>
        <div className={styles.nameSection}>
          <p className={styles.lead}>Our Leaders</p>
          <h4 className={styles.name}>Our Vicar</h4>
          <p>Engineer Innocent Jiji</p>
          <div className={styles.dignitaries}>
            <Link to={"/dignitaries"}>
              <p>View Dignitaries</p>
            </Link>
            <img src={arrowRight} alt="" />
          </div>
        </div>
        <img src={vicar} alt="" />
      </div>
      <div className={styles.programSection}>
        <p className={styles.program}>Explore Our Programs</p>
        <Link to={"/programs"}>
          <p className={styles.blue}>See all</p>
        </Link>
      </div>
      <div className={styles.programContainer}>
        <div className={styles.gridView}>
          {displayedPrograms.map((program, index) => (
            <div key={index}>
              <img src={program.image} alt="" className={styles.programImage} />
              <p className={styles.programTitle}>{program.title}</p>
              <p className={styles.programSummary}>{program.summary}</p>
              <Link to={"/programDetails"}>
                <div className={styles.viewSection}>
                  <p>View Program</p>
                  <img src={arrowRight} alt="" />
                </div>
              </Link>
              <div className={styles.programTagSection}>
                {program.tags.map((tag, idx) => {
                  const { backgroundColor, color } = tagStyles[tag];
                  return (
                    <span
                      key={idx}
                      style={{
                        backgroundColor,
                        color,
                        padding: "5px 10px",
                        borderRadius: "5px",
                        marginRight: "10px",
                        fontWeight: "400",
                        fontSize: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      {tag}
                    </span>
                  );
                })}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles.aboutUsSection}>
        <div className={styles.aboutUsText}>
          <p className={styles.caption}>Raising Leaders</p>
          <h4>About Us</h4>
          <p className={styles.description}>
            His Grace Anglican Church Chevron is a family of peculiar people
            currently worshiping at the Event Hall, Limeridge Hotel (Plot 10),
            Chevron Drive, Lekki, Lagos, Nigeria. Our crust is to raise leaders
            who will transform the world through the word of God.
          </p>
        </div>
        <img src={aboutUsImage} alt="" />
      </div>
      <div className={styles.announcement}>
        <img src={streamImage} alt="" />
        <div className={styles.centered}>
          <h5>Become Part of Our Faith Family</h5>
          <p>Join Us Today And Become A part Of Our Church Community</p>
          <Link to={"/membership"}>
            <button className={styles.memberBtn}>Membership Form</button>
          </Link>
        </div>
      </div>
    </main>
  );
};

export default Main;
