import Dignitaries from "../components/dignitaries/main";
import Footer from "../layouts/footer";

const DignitariesPage = () => {
  return (
    <>
      <Dignitaries />
      <Footer/>
    </>
  );
};

export default DignitariesPage;
