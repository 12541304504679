import React from "react";
import styles from "./index.module.scss";

const Flutterwave = () => {
  return (
    <div className={styles.container}>
      <div className={styles.largeFlex}>
        <h2>Payment Information</h2>
        <img src="./flutterwave.svg" alt="" />
      </div>
      <span>Amount</span>
      <div className={styles.amountInputField}>
        <div className={styles.dropDown}>
            <p>NGN</p>
            <img src="/dropDown.svg" alt="" />
        </div>
        <input type="text" placeholder="$192.00 USD" />
      </div>
      <div className={styles.largeFlex}>
        <div className={styles.formattedStyling}>
          <span>First Name</span>
          <div className={styles.inputStyling}>
            <input type="text" placeholder="Enter name" />
          </div>
        </div>
        <div className={styles.subStyling}>
          <span>Last Name</span>
          <div className={styles.inputStyling}>
            <input type="text" placeholder="Enter name" />
          </div>
        </div>
      </div>
      <span>Email Address</span>
      <div className={styles.cardInputField}>
        <input type="text" placeholder="Enter email" />
      </div>
      <span>Narration</span>
      <div className={styles.cardInputField}>
        <input type="text" placeholder="Enter Narration" />
      </div>
      <button className={styles.payment}>Make Payment</button>
    </div>
  );
};

export default Flutterwave;
