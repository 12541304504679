import React from "react";
import SupportPage from "../components/support/main";
import Footer from "../layouts/footer";

const Support = () => {
  return (
    <>
      <SupportPage />
      <Footer/>
    </>
  );
};

export default Support;
