import { useState } from "react";
import SearchAndButton from "../../layouts/searchAndButton";
import styles from "./main.module.scss";
import bigImage from "../../assets/bigImage.svg";
import share from "../../assets/share.svg";
import location from "../../assets/Location.svg";
import { guestSpeakers } from "../../utils/guestSpeakers";
import RegisterEvent from "../../layouts/registerEvent";
import { Modal } from "antd";

const EventDetails = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleOk = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };


  return (
    <div>
      <SearchAndButton title={"Welcome to His Grace Anglican Church"} />
      <h4>Event Title</h4>
      <div className={styles.eventTitleContainer}>
        <img src={bigImage} alt="" className={styles.bigImage} />
        <div>
          <div className={styles.titleAndShare}>
            <p>Event Title</p>
            <img src={share} alt="" />
          </div>
          <p>A short summary about the details of the event</p>
          <div className={styles.locationAndTime}>
            <div className={styles.location}>
              <img src={location} alt="" />
              <p>City Stadium</p>
            </div>
            <p>8:00pm</p>
          </div>
          <div className={styles.tagSection}>
            <div className={styles.gbg}>
              <p>Crusade</p>
            </div>
            <div className={styles.ybg}>
              <p>Revival</p>
            </div>
            <div className={styles.bbg}>
              <p>Crossover</p>
            </div>
          </div>
          <button
            className={styles.register}
            onClick={() => setModalOpen(true)}
          >
            Register Now
          </button>
        </div>
        <div className={styles.guestContainer}>
          <p className={styles.guestTitle}>Guest Speakers</p>
          <div className={styles.guests}>
            {guestSpeakers.map((item, index) => (
              <div className={styles.guestItem} key={index}>
                <img src={item.img} alt="" />
                <p className={styles.guestName}>{item.name}</p>
                <p className={styles.guestPosition}>{item.position}</p>
                <p>{item.description}</p>
                <div className={styles.media}>
                  {item.socialMedia.map((socails, position) => (
                    <img src={socails} alt="" key={position} />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* {isModalOpen && (
        <RegisterEvent
          btnText={"Register for Event"}
          success={isModalOpen}
          closeSuccess={setModalOpen}
        />
      )} */}
       <Modal
        title='Register for Event'
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Register"
        footer={null}
        width={600}
        className="custom-modal"
      >
        <div className={styles.container}>
            <div className={styles.formContainer}>
              <div className={styles.inputStyling}>
                <p>Full Name</p>
                <div className={styles.inputContainer}>
                  <input type="text" placeholder="Enter full Name" />
                </div>
              </div>
              <div className={styles.inputStyling}>
                <p>Email</p>
                <div className={styles.inputContainer}>
                  <input type="text" placeholder="Enter Email"/>
                </div>
              </div>
              <div className={styles.inputStyling}>
                <p>Phone Number</p>
                <div className={styles.inputContainer}>
                  <input type="text" placeholder="Enter number"/>
                </div>
              </div>
            </div>
            <button className={styles.btn}>Register for Event</button>
          </div>
      </Modal>
    </div>
  );
};

export default EventDetails;
