import React from "react";
import bell from "../../assets/bell.svg";
import styles from "./main.module.scss";
import search from "../../assets/search.svg";
import EmptyImage from "../../assets/emptyImage.svg";
import { redirectToYoutube } from "../../utils/types";

const main = () => {
  return (
    <div>
      <div className={styles.headerContainer}>
        <h4>Support</h4>
        <img src={bell} alt="" />
      </div>
      <div className={styles.miniHeader}>
        <div className={styles.searchContainer}>
          <img src={search} alt="search" className={styles.search} />
          <input type="text" placeholder="Search..." />
        </div>
        <button className={styles.liveBtn} onClick={redirectToYoutube}>Join Live Stream</button>
      </div>
      <div className={styles.bodyContainer}>
        <img src={EmptyImage} alt="" />
        <div className={styles.formContainer}>
          <p>Got Questions?</p>
          <h5>Contact Us</h5>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          <div className={styles.inputContainer}>
            <p>Name</p>
            <div className={styles.inputStyling}>
              <input type="text" placeholder="Enter your name" />
            </div>
          </div>
          <div className={styles.inputContainer}>
            <p>Email</p>
            <div className={styles.inputStyling}>
              <input type="text" placeholder="Enter your name" />
            </div>
          </div>
          <div>
            <p>Message</p>
            <textarea name="" id="" placeholder="Enter your message"></textarea>
          </div>
          <div className={styles.terms}>
            <input type="checkbox" />
            <p>I accept the terms</p>
          </div>
          <button>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default main;
