import React from "react";
import TestimonyPage from "../components/testimony/main";
import Footer from "../layouts/footer";

const Testimony = () => {
  return (
    <>
      <TestimonyPage />
      <Footer/>
    </>
  );
};

export default Testimony;
