export const programs = [
    {
        image: '/alterOfFire.svg',
        title: 'Altar of Fire',
        summary: 'A Powerful Worship Experience',
        tags: ["Worship", "Prayer", "Fellowship", "Revival"]
    },
    {
        image: '/wordClinic.svg',
        title: 'Word Clinic',
        summary: 'A Life-changing Bible Study',
        tags: ["Bible", "Knowlegde", "Growth", "Wisdom"]
    },
    {
        image: '/community.svg',
        title: 'Community Outreach',
        summary: 'Make a Difference in Our Community',
        tags: ["Service", "Outreach", "Compassion", "Impact"]
    },
    {
        image: '/youth.svg',
        title: 'Youth Fellowship',
        summary: 'Empowering the Next Generation',
        tags: ["Growth", "Youth", "Fellowship", "Revival"]
    },
    {
        image: '/prayer.svg',
        title: 'Prayer Meeting',
        summary: 'Experience the Power of Prayer',
        tags: ["Spiritual", "Healing", "Prayer", "Wisdom"]
    },
    {
        image: '/community.svg',
        title: 'Community Outreach',
        summary: 'Make a Difference in Our Community',
        tags: ["Service", "Outreach", "Compassion", "Impact"]
    },
]