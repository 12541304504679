import { NavLink } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as Home } from "../assets/home.svg";
import { ReactComponent as Line } from "../assets/line.svg";
import { ReactComponent as Event } from "../assets/events.svg";
import { ReactComponent as Program } from "../assets/program.svg";
import { ReactComponent as Resource } from "../assets/resouces.svg";
import { ReactComponent as Profile } from "../assets/profile.svg";
import { ReactComponent as Support } from "../assets/support.svg";
import { ReactComponent as Contribution } from "../assets/contributions.svg";
import { ReactComponent as Request } from "../assets/request.svg";
import { Divider, Menu } from "antd";
import styles from "./dashboardLayout.module.scss";

const Sidebar = () => {

  const nonDropdownItems = [
    {
      key: "home",
      label: <NavLink to="/">Home</NavLink>,
      icon: <Home />,
    },
    {
      key: "event",
      label: <NavLink to="/events">Events</NavLink>,
      icon: <Event />,
    },
    {
      key: "resource",
      label: <NavLink to="/resources">Resources</NavLink>,
      icon: <Resource/>,
    },
    {
      key: "programs",
      label: <NavLink to="/programs">Programs</NavLink>,
      icon: <Program />,
    },
  ]

  const nonDropdownMidItems = [
    {
      key: "contribution",
      label: <NavLink to="/contributions">Contributions</NavLink>,
      icon: <Contribution />,
    },
  
  ];

  const dropdownItems = [
    {
      key: "requests",
      label: "Requests",
      icon: <Request />,
      children: [
        {
          key: "prayerRequest",
          label: <NavLink to="/prayer-request">Prayer Request</NavLink>,
          icon: <Line />,
        },
        {
          key: "testimony",
          label: <NavLink to="/testimony">Testimony</NavLink>,
          icon: <Line />,
        },
      ],
    },
  ];

  const nonDropdownEndItems = [
    {
      key: "profile",
      label: <NavLink to="/profile">Profile</NavLink>,
      icon: <Profile />,
    },
    {
      key: "support",
      label: <NavLink to="/support">support</NavLink>,
      icon: <Support />,
    },
  ];

  return (
    <section>
      <div style={{ backgroundColor: "#0B91F1", padding: "10px 0" }}>
        <div style={{ textAlign: "center", }}>
          <Logo />
        </div>
      </div>

      <div>
        <Menu mode="inline">

          {nonDropdownItems.map((item) => (
            <Menu.Item
              key={item.key}
              icon={item.icon }
            >
              {item.label}
            </Menu.Item>
          ))}

          <Divider className={styles.divider}/>

          {nonDropdownMidItems.map((item) => (
            <Menu.Item
              key={item.key}
              icon={item.icon }
            >
              {item.label}
            </Menu.Item>
          ))}

         

          {dropdownItems.map((item) => (
            <Menu.SubMenu key={item.key} icon={item.icon} title={item.label}>
              {item.children.map((child) => (
                <Menu.Item
                  key={child.key}
                  icon={child.icon}
                >
                  {child.label}
                </Menu.Item>
              ))}
            </Menu.SubMenu>
          ))}

          <Divider className={styles.divider}/>

          {nonDropdownEndItems.map((item) => (
            <Menu.Item
              key={item.key}
              icon={item.icon }
            >
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    </section>
  );
};

export default Sidebar;
